.infra-life {
    padding: 60px 0 60px;
}

.gRades .container{
    max-width: 1300px;

}

.gRades .sec-heading h5 {
    margin-top: 0;
    color: #ff3786;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
}
.gRades .sec-heading h5:before {
    content: "";
    background-color: #ffc20e;
    width: 2px;
    height: 20px;
    top: 10px;
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
}
.gRades .infra-content {
    padding: 20px 0;
}

.gRades .infra-content p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.gRades .rooms-sec {
    background-color: #eaeaea;
    border-radius: 25px;
    border: 1px solid rgba(0, 0, 0, 0.0901960784);
    overflow: hidden;
}

.gRades .room-one {
    display: flex;
    overflow: hidden;

}

.gRades .expression-tab {
    width: 25%;
    padding-left: 0;
}
.gRades .expression-tab li.nav-link.active {
    color: #ff3786;
    font-weight: 900;
    font-size: 18px;
}
.gRades .expression-tab li {
    padding: 10px 0 0 60px;
    height: 50px;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    border-bottom: 1px solid #000;
}

.gRades .expression-tab li.nav-link.active:before {
    content: "";
    background-color: #ffc20e;
    width: 20px;
    height: 50px;
    position: relative;
    left: -60px;
    top: -10px;
}

.gRades .expression-tab + .tab-content {
    width: 75%;
}
.owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translateZ(0);
}
.gRades .owl-stage {
    position: relative;}

    .gRades   .m-img{
position: relative;
    } 
    
    .gRades   .m-img img{
height: 350px;
    }
.gRades .m-txt {
    position: absolute;
    bottom: 0;
    padding: 10px 60px 0;
    background-color: #00000070;
    border-radius: 0 0 25px 0;
}
.gRades .m-txt h3 {
    color: #ffc20e;
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
}

.gRades .m-txt p {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
}
@media(max-width:990px){
    .gRades .container{
        max-width: 700px;
    
    }
    .gRades  .expression-tab {
        width: 35%;
    }
}
@media(max-width:769px){

    .gRades  .rooms-sec {
        background-color: #fff;
        border: none;
        border-radius: 0;
        
    }
    .gRades .room-one {
        display: block !important;
        /* background: #016aa7;
    border-radius: 10px; */
    }

    .gRades .scroll-btns {
        position: relative;
        display: block !important;
        width: 100%;
        left: 0;
    }

    .gRades .scroll-left {
        position: absolute;
        left: 0;
        background: none;
        border: none;
        padding-top: 12px;
    }
    
    [type="button"]:not(:disabled){
        cursor: pointer;
    }

    .gRades .scroll-btns   button:not(:disabled) {
        cursor: pointer;
    }

    .gRades .scroll-btns .scroll-right {
        position: absolute;
        right: 0;
        background: none;
        border: none;
        padding-top: 12px;
    }

    .gRades .different-tabs {
        display: -webkit-box;
        overflow-x: auto;
        background-color: #f9f9f9;
        border-radius: 10px;
    }
    .gRades .expression-tab {
        width: 100%;
        display: flex;
        overflow: auto;
    }
    .gRades .expression-tab li.nav-link.active {
        content: "";
        background-color: #ff3786;
        color: #fff;
        border-radius: 10px;
        height: auto;
        position: relative;
    }

    .gRades .expression-tab li.nav-link.active:before {
        display: none ;
    }
    .gRades .expression-tab li {
        padding: 5px 5px 5px 0px;
        height: 31px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        border-bottom: 0px solid #000;
        margin-right: 14px;
        width: 500px;
    }
    .gRades .expression-tab li.nav-link.active {
        color: white;
        font-weight: 700;
        font-size: 16px;
        
    }
    .gRades .m-img {
        position: relative;
        margin-top: 20px;
    }
    .gRades .m-img img {
        height: 310px !important;
        border-radius: 10px;
        width: 100% !important;
        vertical-align: middle;
    }
    .gRades .expression-tab + .tab-content {
        width: 100%;
    }
}