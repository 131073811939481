
.facility
{
    background-image: url(../../../../public/img/bg-vision.jpg);
    background-repeat: repeat;
    /* background-position: center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 20px 0px;
}

.facility .overlay-theme-colored-4:before {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.facility .layer-overlay::before {
    background: rgba(17, 17, 17, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.facility .container{
    padding-top: 60px !important;
    padding-bottom: 40px !important;
    max-width: 1140px;
}

.facility .row {
    margin-right: -15px;
    margin-left: -15px;
}
.facility-box{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.facility .active{
    color: #ff3786;
}
.facility .text-theme-color-2 {
    color: #ff3786;
}

.facility .mt-10 {
    margin-top: 10px !important;
}

.facility  .breadcrumb>li {
    display: inline-block;
}

.facility .breadcrumb.white li a {
    color: #333333;
    text-decoration: none;
}

.facility .breadcrumb > li + li::before {
    content: "\f04b";
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 5px;
    padding: 0 5px;
    color: #333333;
    content: "/\00a0";
}

.facility-box .container{
max-width: 1140px;
}

.facility-box .pt-40 {
    padding-top: 40px !important;
}

.facility-box .text-theme-color-2 {
    color: #ff3786;
}
.facility-box .facility-h3{
    font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.facility-box .double-line-bottom-theme-colored-2 {
    margin-bottom: 20px;
    margin-top: 8px;
    padding-bottom: 5px;
    position: relative;
}
.facility-box .double-line-bottom-theme-colored-2:before {
    border-radius: 8px;
    bottom: -1px;
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 24px;
    background: #ff3786;
}

.facility-box .double-line-bottom-theme-colored-2:after {
    border-radius: 8px;
    bottom: 1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 64px;
    background: #ff3786;
}

.facility-box hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #7f7979;
}
.facility-box .container{
max-width: 1140px;
}



.facility-img1{
  background-image: url('../../../../public/img/7c.jpg'); 
  background-repeat: no-repeat; 
}
.facility-img2{
    background-image: url('../../../../public/img/1.jpg'); 
    background-repeat: no-repeat;
    height: 250px;
  }
  .facility-img3{
    background-image: url('../../../../public/img/2.jpg'); 
    background-repeat: no-repeat;
    height: 250px; 
  }
  .facility-img4{
    background-image: url('../../../../public/img/3.jpg'); 
    background-repeat: no-repeat;
    height: 250px;  
  }
  .facility-img5{
    background-image: url('../../../../public/img/4.jpg'); 
    background-repeat: no-repeat; 
    height: 250px; 
  }

  .facility-img6{
    background-image: url('../../../../public/img/5.jpg'); 
    background-repeat: no-repeat;
    height: 250px;  
  }
  .facility-img7{
    background-image: url('../../../../public/img/6.jpg'); 
    background-repeat: no-repeat; 
    height: 250px; 
  }

  .facility-box1{
    position: relative;
    min-height: 1px;
  }