 .key-pillers {
    padding: 50px 0;
}
.key-pillers .item{
    width: 416px !important; 
}
.key-pillers .container {
    max-width: 1300px;
    margin:0px auto; 
}
.key-pillers .sec-heading h5 {
    margin-top: 0;
    color: #ff3786;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
    margin-right: 10px;
}

.key-pillers .sec-heading h5:before {
    content: "";
    background-color: #ffc20e;
    width: 2px;
    height: 20px;
    top: 10px;
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
}

.key-pillers .sec-heading p {
    font-size: 20px !important;
    line-height: 30px !important;
    margin: 20px 0;
    text-align:left;
}

.key-pillers .key {
    padding-top: 20px;
}

.key-pillers .key .block {
    position: relative;
    height: 350px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 20px;
}
.key-pillers .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 50%;
    background-size: cover;
    border-radius: 20px;
    background-color: #fcfeff;
}

.key-pillers .bg img {
    left: 37%;
    top: 25%;
    position: absolute;
    width: 100px;
}
.key-pillers .block-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    transition: all 0.4s ease-in-out;
    border-radius: 20px;
}
.key-pillers .hover-img {
    opacity: 0.9;
    max-height: 100px;
    overflow: hidden;
    position: absolute;
    top: 100%;
}

.key-pillers .block-overlay h4 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 180px;
    text-align: center;
    color: #ff3786;
    transition: all 0.4s ease-in-out;
}

.key-pillers .block-overlay p {
    color: #fff;
    font-size: 15px;
    opacity: 0;
    font-weight: 300;
    overflow: hidden;
    text-align: center;
    top: 100%;
    line-height: 20px;
    transition: all 0.4s ease-in-out;
}

.key-pillers .block:hover .block-overlay {
    background-color: #ff3786;
}

.key-pillers .block:hover .block-overlay .hover-img {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
}
.key-pillers .block-overlay .hover-img {
    opacity: 0.9;
    max-height: 100px;
    overflow: hidden;
    position: absolute;
    top: 100%;
}
.key-pillers .block:hover .block-overlay h4 {
    font-size: 20px;
    margin-top: 80px;
    color: #ffc20e;
}
.key-pillers .block:hover .block-overlay p {
    top: 180px;
    opacity: 1;
}
@media (max-width:991px) {
    .key-pillers .item{
        width: 330px !important; 
    }
    .key-pillers .container {
        max-width: 689px;
        margin:0px auto; 
    }
    .key-pillers .sec-heading p {
        font-size: 16px !important;
        line-height: 22px !important;
        margin: 20px 0;
        text-align: left;
    }

    .key-pillers .pillars-carousel{
        max-width: 698px;
        margin: 0px auto;
    }
   

    

}

@media (max-width:700px) {
   
    .key-pillers .sec-heading{
        padding: 0px 20px;
        }
        .key-pillers .sec-heading p{
            text-align:justify;
        }
        .key-pillers .container {
            max-width: 400px;
            margin:0px auto; 
        }
        .key-pillers .pillars-carousel{
            max-width: 500px;
            margin: 0px auto;
        }
}
@media (max-width:700px) {}