.footer-tab ul{
    padding-left: 0;
}

.footer-tab li {
    list-style: none;
    
}
.footer-tab li a{
    text-decoration: none;
    color: black;
    text-align: left;
}

.footer-top {
    background-color: #f3f3f3;
    padding: 40px 0 10px;
}

.footerSocialIcon{
    display: inline-block;  
    font-size: 30px;
    color:  white;
    width: 50px;
    height: 50px;
    padding: 1px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: deeppink;
}
.footerSocialIcon:hover{
    background-color: white;
    color: black;
    border: 1px solid black;
    box-sizing: border-box;
}
