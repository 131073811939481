.infraSTructure {
    padding: 60px 0;
    background-color: #f9f9f9;
}
.infraSTructure .container {
    max-width: 1300px;
    margin:0px auto; 
}

.infraSTructure .sec-heading h5 {
    color: #ff3786;
    text-transform: uppercase;
    margin: auto auto 20px;
    font-size: 24px;
    line-height: 39px;
    display: inline-block;
    padding: 0;
    border-left: none;
    border-bottom: 2px solid #ffc20e;

}

.infraSTructure .sec-heading p {
    font-size: 20px !important;
    line-height: 30px !important;
    margin-left: 10px;
}
.infraSTructure .laptopShow{
    padding-top: 20px;
}

.infraSTructure .row{
    -bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -0.5);
    margin-left: calc(var(--bs-gutter-x)* -0.5);
}
.infraSTructure .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
.infraSTructure  .block {
    position: relative;
    height: 360px;
    margin-bottom: 20px;
    overflow: hidden;
}
.infraSTructure .spaces-row .block .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 50%;
    background-size: cover;
    border-radius: 20px;
}
.infraSTructure .spaces-row .block:hover .block-overlay {
    background-color: rgba(0, 0, 0, 0.2392156863);
}
.infraSTructure .spaces-row .block:hover .block-overlay h4 {
    font-size: 16px;
    margin-top: 0;
}

.spaces-mb{
    display: none;
}

@media (max-width:991px) {
    .infraSTructure .sec-heading p {
        font-size: 16px !important;
        line-height: 22px !important;
        margin-left: 10px;
    }
    .infraSTructure .container {
        max-width: 689px;
        margin:0px auto; 
    }
    
}
@media (max-width:768px){
    .spaces-mb{
        display: block;
    }
    .laptopShow{
        display: none;
    }
}
@media (max-width:567px) {
    .infraSTructure .sec-heading p {
        font-size: 16px !important;
        line-height: 23px !important;
        margin-left: 10px;
        text-align: center;
    }
    .infraSTructure .container {
        max-width: 400px;
        margin: 0px auto;
        padding: 0px 20px;
    }
    .infraSTructure .container p{
        text-align:justify;
    }
    
}





.infraSTructure .bg1{
    background-image: url(../../../public/img/library_final.jpg);
    
}

.infraSTructure .bg2{
    background-image: url(../../../public/img/classroom_final.jpg);  
}
.infraSTructure .bg3{
    background-image: url(../../../public/img/artstudio_final.jpg);  
}
.infraSTructure .bg4{
    background-image: url(../../../public/img/sports_final.jpg);  
}
.infraSTructure .bg5{
    background-image: url(../../../public/img/dance_studio_final.jpg);  
}
.infraSTructure .bg6{
    background-image: url(../../../public/img/sciencelabs_final.jpg);  
}
.infraSTructure .bg7{
    background-image: url(../../../public/img/Guitar.jpg);  
}