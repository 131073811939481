
.vision
{
    background-image: url(../../../../public/img/bg-vision.jpg);
    background-repeat: repeat;
    /* background-position: center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 20px 0px;
}

.vision .overlay-theme-colored-4:before {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.vision .layer-overlay::before {
    background: rgba(17, 17, 17, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.vision .container{
    padding-top: 60px !important;
    padding-bottom: 40px !important;
    max-width: 1140px;
}

.vision .row {
    margin-right: -15px;
    margin-left: -15px;
}
.vision-box{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.vision .active{
    color: #ff3786;
}
.vision .text-theme-color-2 {
    color: #ff3786;
}

.vision .mt-10 {
    margin-top: 10px !important;
}

.vision  .breadcrumb>li {
    display: inline-block;
}

.vision .breadcrumb.white li a {
    color: #333333;
    text-decoration: none;
}

.vision .breadcrumb > li + li::before {
    content: "\f04b";
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 5px;
    padding: 0 5px;
    color: #333333;
    content: "/\00a0";
}



.vision-box .pt-40 {
    padding-top: 40px !important;
}

.vision-box .text-theme-color-2 {
    color: #ff3786;
}
.vision-box .vision-h3{
    font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.vision-box .double-line-bottom-theme-colored-2 {
    margin-bottom: 20px;
    margin-top: 8px;
    padding-bottom: 5px;
    position: relative;
}
.vision-box .double-line-bottom-theme-colored-2:before {
    border-radius: 8px;
    bottom: -1px;
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 24px;
    background: #ff3786;
}

.vision-box .double-line-bottom-theme-colored-2:after {
    border-radius: 8px;
    bottom: 1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 64px;
    background: #ff3786;
}

.vision-box hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #7f7979;
}
.vision-box .container{
max-width: 1140px;
}