.schoolLife .sec-heading h5 {
    margin-top: 0;
    color: #ff3786;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
}

.schoolLife .sec-heading h5:before {
    content: "";
    background-color: #ffc20e;
    width: 2px;
    height: 20px;
    top: 10px;
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
}
.schoolLife .container{
max-width: 1300px;
margin: 0px auto;
}
.footer-blue {
    margin: auto;
}

.schoolLife .bg-foot {
    background-color: #ab3d69;
    border-radius: 32px;
    box-shadow: 0 0 16px hsla(0, 0%, 46.3%, 0.1098039216);
}

.schoolLife .vl {
    border-right: 2px solid #b1a0cc;
    margin: 60px 0;
}

.schoolLife .bg-foot h3 {
    color: #fff;
    font-size: 22px;
    line-height: 30px;
}
.schoolLife .bg-foot .btn {
    margin: 20px 0 0;
    padding: 5px 40px;
    --bs-btn-border-color: hsla(0, 0%, 100%, 0.6196078431372549);
}

@media (max-width:991px) {
    .schoolLife .container {
        max-width: 689px;
        margin:0px auto; 
    }
}

@media (max-width:700px) {
    .schoolLife .container {
        max-width: 400px;
        margin:0px auto; 
    }
}