
.Fee
{
    background-image: url(../../../../public/img/bg-aboutus.jpg);
    background-repeat: repeat;
    /* background-position: center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 20px 0px;
}

.Fee .overlay-theme-colored-4:before {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.Fee .layer-overlay::before {
    background: rgba(17, 17, 17, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.Fee .container{
    padding-top: 60px !important;
    padding-bottom: 40px !important;
    max-width: 1140px;
}

.Fee .row {
    margin-right: -15px;
    margin-left: -15px;
}
.Fee-box{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.Fee .active{
    color: #105399;
}
.Fee .text-theme-color-2 {
    color: #105399;
}

.Fee .mt-10 {
    margin-top: 10px !important;
}

.Fee  .breadcrumb>li {
    display: inline-block;
}

.Fee .breadcrumb.white li a {
    color: #333333;
    text-decoration: none;
}

.Fee .breadcrumb > li + li::before {
    content: "\f04b";
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 5px;
    padding: 0 5px;
    color: #333333;
    content: "/\00a0";
}
