 .welcome-sec {
    padding: 60px 0;
    background-color: #f9f9f9;
}
.welcome-sec .container {
    max-width: 1300px;
    margin:0px auto; 
}

.welcome-sec .sec-heading h5 {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 34px;
    color: #ff3786;
    text-transform: uppercase;

}

.welcome-sec .sec-heading p {
    font-size: 20px !important;
    line-height: 30px !important;
    margin-left: 10px;
}
@media (max-width:991px) {
    .welcome-sec .sec-heading p {
        font-size: 16px !important;
        line-height: 22px !important;
        margin-left: 10px;
    }
    .welcome-sec .container {
        max-width: 689px;
        margin:0px auto; 
    }
    
}
@media (max-width:567px) {
    .welcome-sec .sec-heading p {
        font-size: 16px !important;
        line-height: 23px !important;
        margin-left: 10px;
        text-align: center;
    }
    .welcome-sec .container {
        max-width: 400px;
        margin: 0px auto;
        padding: 0px 20px;
    }
    .welcome-sec .container p{
        text-align:justify;
    }
    
}