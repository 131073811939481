
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 0px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    color:black;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 15px 5px;
    padding: 0;
    cursor: pointer;
    color: #e21f6d;
}


.slick-dots li.slick-active button:before {
    opacity: .75;
    color:#e21f6d;
}





.SportsPrograms{
    padding: 45px 0;
    overflow: hidden;
}


.SportsPrograms .block {
    position: relative;
    height: 360px;
    margin-bottom: 20px;
    overflow: hidden;
}

.SportsPrograms .sec-heading h5 {
    margin-top: 0;
    color: #ff3786;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
    margin-right: 10px;
}

.SportsPrograms .sec-heading h5:before {
    content: "";
    background-color: #ffc20e;
    width: 2px;
    height: 20px;
    top: 10px;
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
}

.SportsPrograms .sec-heading p {
    font-size: 20px !important;
    line-height: 30px !important;
    margin: 20px 0;
    text-align:left;
}

.SportsPrograms  .custom{
   margin: 1rem 0px 2rem;
}
.SportsPrograms .container{
    max-width: 1300px;
    margin: 0px auto;
}
.SportsPrograms .item{
    width: 310px !important;
    margin-right: 10px;

}

.SportsPrograms .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    
}
.SportsPrograms .bg{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 80%;
    background-size: cover;
    border-radius: 20px;
}

.SportsPrograms .block-overlay {
    background-color: rgba(0, 0, 0, 0.1011764706);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    transition: all 0.4s ease-in-out;
    border-radius: 20px;
}

.SportsPrograms .block-overlay h4 {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 280px;
    color: #fff;
    transition: all 0.4s ease-in-out;
}

.SportsPrograms .block-overlay p {
    text-align: center;
    color: #fff;
    font-size: 15px;
    opacity: 0.9;
    padding: 0 30px 0 0;
    font-weight: 300;
    overflow: hidden;
    position: absolute;
    top: 100%;
    opacity: 1;
    line-height: 20px;
    transition: all 0.4s ease-in-out;
}
.SportsPrograms .block:hover .block-overlay {
    background-color: rgba(0, 0, 0, 0.2392156863);
}
.SportsPrograms .block:hover .block-overlay h4 {
    font-size: 16px;
    margin-top: 0;
}

.SportsPrograms .block:hover .block-overlay p {
    top: 65px;
}

@media (max-width:991px) {
    .SportsPrograms .item{
        width: 330px !important; 
    }
    .SportsPrograms .container {
        max-width: 689px;
        margin:0px auto; 
    }
    .SportsPrograms .sec-heading p {
        font-size: 16px !important;
        line-height: 22px !important;
        margin: 20px 0;
        text-align: left;
    }

    .SportsPrograms .pillars-carousel{
        max-width: 698px;
        margin: 0px auto;
    }
   

    

}

@media (max-width:700px) {
   
    .SportsPrograms .sec-heading{
        padding: 0px 20px;
        }
        .SportsPrograms .sec-heading p{
            text-align:justify;
        }
        .SportsPrograms .container {
            max-width: 400px;
            margin:0px auto; 
        }
        .SportsPrograms .pillars-carousel{
            max-width: 500px;
            margin: 0px auto;
        }
}


























.SportsPrograms .bg{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 50%;
    background-size: cover;
    border-radius: 20px;
}
.SportsPrograms .bg1{
    background-image: url(../../../public/img/Fencing.jpg);
    
}

.SportsPrograms .bg2{
    background-image: url(../../../public/img/Martial-arts.jpg);  
}
.SportsPrograms .bg3{
    background-image: url(../../../public/img/Archery.jpg);  
}
.SportsPrograms .bg4{
    background-image: url(../../../public/img/Badminton.jpg);  
}
.SportsPrograms .bg5{
    background-image: url(../../../public/img/Basket-ball.jpg);  
}
.SportsPrograms .bg6{
    background-image: url(../../../public/img/Chess.webp);  
}
.SportsPrograms .bg7{
    background-image: url(../../../public/img/Cricket.jpg);  
}

.SportsPrograms .bg8{
    background-image: url(../../../public/img/Capoeira.jpg);  
}
.SportsPrograms .bg9{
    background-image: url(../../../public/img/Football.jpg);  
}
.SportsPrograms .bg10{
    background-image: url(../../../public/img/Gymnastics.jpg);  
}
.SportsPrograms .bg11{
    background-image: url(../../../public/img/Shooting.jpg);  
}
.SportsPrograms .bg12{
    background-image: url(../../../public/img/Skating.jpg);  
}
.SportsPrograms .bg13{
    background-image: url(../../../public/img/swimming.jpg);  
}
.SportsPrograms .bg14{
    background-image: url(../../../public/img/Table-tennis.jpg);  
}