
.item{
    width:100%;
   

}
.box1{
    width:100%;
    overflow: hidden;
}


.dest1{
       display: block !important;
	    height: 600px;
}
.mobile1{
    display: none !important;
}







@media only screen and (max-width: 567px){
    

    .dest1{
       display: none !important;
      
}

.mobile1{
       display: block !important;
       width: 100% !important;
}
}
    
