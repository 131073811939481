.admission-banner-sec {
    position: relative;
}

.admission-banner-sec .admission-banner-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    display: flex;
    align-items: center;
}
.admission-banner-sec .container{
    
    height: 100% !important;
}

.admission-banner-sec .admission-form {
    background-color: #ff3786;
    border-radius: 30px;
    padding: 20px 30px;
    color: #fff;
    width: auto;
}
.admission-banner-sec .admission-form h5 {
    font-size: 17px;
    margin-bottom: 0;
    line-height: 25px;
}
.admission-banner-sec .admission-form p {
    margin-bottom: 0;
    font-size: 14px;
}

.admission-banner-sec .form-group {
    margin-bottom: 10px;
}
.admission-banner-sec .form-control {
    border-radius: 10px;
    border: 0;
    background-color: #f5f5f5;
    color: #6c757d;
}

.admission-banner-sec .btn-submit {
    background-color: #ffc20e;
    width: 140px;
    border: none;
    margin: 10px 80px 0;
    height: 35px;
    border-radius: 10px;
    text-align: center;
}