header {
    background-color: #fff;
}


.header .navbar-brand img {
    width: 180px;
    height: auto;
}

.header .navbar-button {
    border-radius: 5px;
    padding: 0 10px;
    background-color: #ff3786;
}

.header .addmission {
    color: #fff;
    border: none;
}
#mynewButton{
   width: 130px;
   position: fixed;
   transform: rotate(90deg);
   top: 150px;
   right: -45px;
}

.whatapp {
    position: fixed;
    width: 100px;
    height: 100px;
    right: 20px;
    bottom: 20px;
}

.whatapp img {
    width: 100%;
}




.nav-link span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background:#ff3786;
    visibility: visible;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .nav-link:hover span:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }