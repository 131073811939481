
.gallery
{
    background-image: url(../../../../public/img/bg-gallery.jpg);
    background-repeat: repeat;
    /* background-position: center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 20px 0px;
}

.gallery .overlay-theme-colored-4:before {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.gallery .layer-overlay::before {
    background: rgba(17, 17, 17, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.gallery .container{
    padding-top: 60px !important;
    padding-bottom: 40px !important;
    max-width: 1140px;
}

.gallery .row {
    margin-right: -15px;
    margin-left: -15px;
}
.gallery-box{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.gallery .active{
    color: #ff3786;
}
.gallery .text-theme-color-2 {
    color: #ff3786;
}

.gallery .mt-10 {
    margin-top: 10px !important;
}

.gallery  .breadcrumb>li {
    display: inline-block;
}

.gallery .breadcrumb.white li a {
    color: #333333;
    text-decoration: none;
}

.gallery .breadcrumb > li + li::before {
    content: "\f04b";
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 5px;
    padding: 0 5px;
    color: #333333;
    content: "/\00a0";
}


.gallery-box{
    max-width: 1200px;
    margin: 20px auto;
}
.gallery-box .gallery-isotope{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: center;
}
.gallery-item{
    width: 23%;
    min-width: 200px;
}
.gallery-item img{
    width: 100%;
}

@media(max-width:769px){
    .gallery-item{
        width: 90%;
        
    }
}