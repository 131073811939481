.instafeed .sec-heading h5 {
    margin-top: 0;
    color: #ff3786;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
}

.instafeed .sec-heading h5:before {
    content: "";
    background-color: #ffc20e;
    width: 2px;
    height: 20px;
    top: 10px;
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
}
.instafeed .container{
max-width: 1300px;
}

@media (max-width:991px) {
    .instafeed .container {
        max-width: 689px;
        margin:0px auto; 
    }
}

@media (max-width:700px) {
    .instafeed .container {
        max-width: 400px;
        margin:0px auto; 
    }
}