






.performingPrograms{
    padding: 50px 0;
    overflow: hidden;
}


.performingPrograms .block {
    position: relative;
    height: 360px;
    margin-bottom: 20px;
    overflow: hidden;
}

.performingPrograms .sec-heading h5 {
    margin-top: 0;
    color: #ff3786;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
    margin-right: 10px;
}

.performingPrograms .sec-heading h5:before {
    content: "";
    background-color: #ffc20e;
    width: 2px;
    height: 20px;
    top: 10px;
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
}

.performingPrograms .sec-heading p {
    font-size: 20px !important;
    line-height: 30px !important;
    margin: 20px 0;
    text-align:left;
}

.performingPrograms  .custom{
   margin: 1rem 0px 2rem;
}
.performingPrograms .container{
    max-width: 1300px;
    margin: 0px auto;
}
.performingPrograms .item{
    width: 310px !important;
    margin-right: 10px;

}

.performingPrograms .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    
}
.performingPrograms .bg{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 50%;
    background-size: cover;
    border-radius: 20px;
}

.performingPrograms .block-overlay {
    background-color: rgba(0, 0, 0, 0.1011764706);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    transition: all 0.4s ease-in-out;
    border-radius: 20px;
}

.performingPrograms .block-overlay h4 {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 280px;
    color: #fff;
    transition: all 0.4s ease-in-out;
}

.performingPrograms .block-overlay p {
    text-align: center;
    color: #fff;
    font-size: 15px;
    opacity: 0.9;
    padding: 0 30px 0 0;
    font-weight: 300;
    overflow: hidden;
    position: absolute;
    top: 100%;
    opacity: 1;
    line-height: 20px;
    transition: all 0.4s ease-in-out;
}
.performingPrograms .block:hover .block-overlay {
    background-color: rgba(0, 0, 0, 0.2392156863);
}
.performingPrograms .block:hover .block-overlay h4 {
    font-size: 16px;
    margin-top: 0;
}

.performingPrograms .block:hover .block-overlay p {
    top: 65px;
}

@media (max-width:991px) {
    .performingPrograms .item{
        width: 330px !important; 
    }
    .performingPrograms .container {
        max-width: 689px;
        margin:0px auto; 
    }
    .performingPrograms .sec-heading p {
        font-size: 16px !important;
        line-height: 22px !important;
        margin: 20px 0;
        text-align: left;
    }

    .performingPrograms .pillars-carousel{
        max-width: 698px;
        margin: 0px auto;
    }
   

    

}

@media (max-width:700px) {
   
    .performingPrograms .sec-heading{
        padding: 0px 20px;
        }
        .performingPrograms .sec-heading p{
            text-align:justify;
        }
        .performingPrograms .container {
            max-width: 400px;
            margin:0px auto; 
        }
        .performingPrograms .pillars-carousel{
            max-width: 500px;
            margin: 0px auto;
        }
}


























.performingPrograms .bg{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 50%;
    background-size: cover;
    border-radius: 20px;
}
.performingPrograms .bg1{
    background-image: url(../../../public/img/Pottery.jpg);
    
}

.performingPrograms .bg2{
    background-image: url(../../../public/img/Dance.jpg);  
}
.performingPrograms .bg3{
    background-image: url(../../../public/img/Painting.jpg);  
}
.performingPrograms .bg4{
    background-image: url(../../../public/img/Drums.jpg);  
}
.performingPrograms .bg5{
    background-image: url(../../../public/img/Craft.jpg);  
}
.performingPrograms .bg6{
    background-image: url(../../../public/img/Drama.jpg);  
}
.performingPrograms .bg7{
    background-image: url(../../../public/img/Guitar.jpg);  
}

.performingPrograms .bg8{
    background-image: url(../../../public/img/Music.jpg);  
}
.performingPrograms .bg9{
    background-image: url(../../../public/img/Speech.jpg);  
}
.performingPrograms .bg10{
    background-image: url(../../../public/img/Theatre.jpg);  
}
.performingPrograms .bg11{
    background-image: url(../../../public/img/Vocals.jpg);  
}
.performingPrograms .bg12{
    background-image: url(../../../public/img/Skating.jpg);  
}
.performingPrograms .bg13{
    background-image: url(../../../public/img/swimming.jpg);  
}
.performingPrograms .bg14{
    background-image: url(../../../public/img/Table-tennis.jpg);  
}