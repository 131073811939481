.educators-sec {
    padding: 50px 0;
}

.educators-sec .container {
    max-width: 1300px;
    margin: 0px auto;

}

.educators-sec .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -0.5);
    margin-left: calc(var(--bs-gutter-x)* -0.5);
}
.educators-sec .m-auto {
    margin: auto !important;
}
.educators-sec .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
}

.educators-sec .sec-heading h5 {
    margin-top: 0;
    color: #ff3786;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 700;
}
.educators-sec .sec-heading p {
    font-size: 20px !important;
    line-height: 30px !important;
}

.educators-sec .nature-sec {
    padding: 10px 0;
}

.educators-sec .nature-sec ul {
    padding-left: 0rem;
    list-style-type: none;
}

.educators-sec .nature-sec ul li {
    padding-bottom: 1rem;
}
.educators-sec .nature-sec img {
    width: 25px;
    height: 25px;
    margin: 0 30px 0 10px;
}

.educators-sec .m-auto {
    margin: auto !important;
}

@media(max-width:990px){
    .educators-sec .row{
        flex-direction: column;
    }
    .educators-sec .col-lg-6 {
        flex: 0 0 auto;
        width: 80%;
    }
}

@media(max-width:768px){
    .educators-sec .row{
        flex-direction: column;
    }
    .educators-sec .col-lg-6 {
        flex: 0 0 auto;
        width: 90%;
    }
    .educators-sec .sec-heading p {
        font-size: 16px !important;
        line-height: 22px !important;
    }    
   
}