.contact
{
    background-image: url(../../../../public/img/bg-aboutus.jpg);
    background-repeat: repeat;
    /* background-position: center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 20px 0px;
}

.contact .overlay-theme-colored-4:before {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.contact .layer-overlay::before {
    background: rgba(17, 17, 17, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.contact .container{
    padding-top: 60px !important;
    padding-bottom: 40px !important;
    max-width: 1140px;
}

.contact .row {
    margin-right: -15px;
    margin-left: -15px;
}
.contact-box{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.contact .active{
    color: #105399;
}
.contact .text-theme-color-2 {
    color: #105399;
}

.contact .mt-10 {
    margin-top: 10px !important;
}

.contact  .breadcrumb>li {
    display: inline-block;
}

.contact .breadcrumb.white li a {
    color: #333333;
    text-decoration: none;
}

.contact .breadcrumb > li + li::before {
    content: "\f04b";
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 5px;
    padding: 0 5px;
    color: #333333;
    content: "/\00a0";
}

.contact-box .pt-60 {
    padding-top: 60px !important;
}
.contact-box .pb-60 {
    padding-bottom: 60px !important;
}
.contact-box .text-theme-color-2 {
    color: #105399;
    margin-bottom: 10px !important;
    font-size: 20px !important;
}
.contact-box h4{
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333333;
}
.contact-box .text-gray{
    color: #808080 !important;
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    line-height: 1.42857143;
    
}
.contact-box .bg-silver-light {
    background-color: #F7F8FA !important;
    border: 1px solid #eeeeee !important;
}

.contact-box .contact-h3{
    font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #333333;
  font-size: 20px;
}

.contact-box .contact-h3 span{
    font-size: 10px;
}

.contact-box .double-line-bottom-theme-colored-2 {
    margin-bottom: 20px;
    margin-top: 8px;
    padding-bottom: 5px;
    position: relative;
}
.contact-box .double-line-bottom-theme-colored-2:before {
    border-radius: 8px;
    bottom: -1px;
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 24px;
    background: #105399;
}

.contact-box .double-line-bottom-theme-colored-2:after {
    border-radius: 8px;
    bottom: 1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 64px;
    background: #105399;
}

.contact-box .mb-30 {
    margin-bottom: 30px !important;
}
.contact-box  .form-control {
    border-radius: 0;
    box-shadow: none;
    height: 45px;
    border: 1px solid #eeeeee;
}

.contact-box  .btn {
    font-size: 14px;
    padding: 8px 22px;
    line-height: 1.38;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}
.contact-box .btn-theme-color-2 {
    background-color: #105399;
}
.contact-info a{
text-decoration:none;
color: black;
}
.contact-info a:hover{
    color: #b5b5b5;
}
.headingBranch{
    color:#ff3786;
}